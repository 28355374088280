.project {
	display: grid;
	width: 100%;
	margin-bottom: 10px;
}

.project-demo-title {
	float: left;
	text-align: left;
	font-size: 18px;
	font-weight: bold;
	grid-column-start: 1;
	grid-column-end: 2;
	grid-row-start: 1;
	grid-row-end: 2;
}

.project-demo-gif {
	margin-top: 10px;
	float: left;
	grid-column-start: 1;
	grid-column-end: 2;
	grid-row-start: 2;
	grid-row-end: 3;
	max-width: 400px;
	max-height: 400px;
}

.project-demo-gif img{
	float: left;
	width: 400px;
	height: 400px;
}

.project-demo-description {
	float: right;
	padding: 10px;
	grid-column-start: 2;
	grid-column-end: 3;
	grid-row-start: 2;
	grid-row-end: 3;
	text-align: center;
	display: flex;
	align-items:center;
	justify-content:center;
}

.project-demo-description p {
}
