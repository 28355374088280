.resume {
	display: inline-block;
	text-align: center;
	width: 100%;
	height: 100%;
}

.info {
	display: grid;
}

.name {
	grid-column-start: 1;
	grid-column-end: 2;
	grid-row-start: 1;
	grid-row-end: 3;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: left;
}

.name p {
	font-size: 18px;
	width: 100%;
}

.location {
	text-align: right;
	grid-column-start: 2;
	grid-column-end: 3;
	grid-row-start: 1;
	grid-row-end: 2;
	font-size: 18px;
	margin: 0;
}

.email {
	text-align: right;
	grid-column-start: 2;
	grid-column-end: 3;
	grid-row-start: 2;
	grid-row-end: 3;
	font-size: 18px;
	margin: 0;
}

.contact-details p {
	clear: both;
	text-align: right;
	margin: 0;
}

.resume .section-name {
	float: left;
	height: 20px;
	width: 100%;
	text-align: left;
	margin-top: 10px;
}

.experience, .education {
	margin-top: 15px;
	margin-bottom: 10px;
	text-align: left;
	float: left;
	border-left: 2px solid #635bff;
	border-radius: 4px;
	padding: 5px;
}

.wrapper-top {
	float: left;
	width: 3%;
	margin-left: -6px;
	margin-top: -5px;
	border-top: 2px solid #635bff;
	border-top-left-radius: 4px;

}

.wrapper-bottom {
	height: 5px;
	float: left;
	width: 3%;
	margin-left: -6px;
	margin-bottom: -5px;
	border-bottom: 2px solid #635bff;
	border-bottom-left-radius: 4px;

}

.experience p {
	margin-top: 5px;
	margin-bottom: 5px;
}

.education p {
	margin-top: 5px;
	margin-bottom: 5px;
}

.role, .school {
	float: left;
}

.duration {
	text-align: right;
	float: right;
}

.description {
	float: left;
	width: 100%;
	font-size: 15px;
}

.technologies {
	float: left;
	width: 100%;
	font-size: 15px;
}

.skills {
	float: left;
	padding: 5px;
	text-align: left;
	font-size: 16px;
}

.resume-project {
	width: 100%;
	margin-top: 15px;
	margin-bottom: 10px;
	float: left;
	display: block;
	border-left: 2px solid #635bff;
	border-radius: 4px;
	padding: 5px;
}

.resume-project p {
	float: left;
	text-align: left;
	margin-top: 5px;
	margin-bottom: 5px;
}

.project-name {
	width: 95%;
}

.project-description {
	font-size: 15px;
	width: 95%;
}

.project-technologies {
	float: left;
	width: 100%;
}

