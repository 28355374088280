body::-webkit-scrollbar {
  display: none;

}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
	font-family: roboto, sans-serif;
	color: #0a2540 !important;
}

.container {
	margin-top: 3% !important;
	max-width: 1024px;
	margin: 0 auto;
}

.mainContent {
	padding: 10px;
	margin-left: 200px;
	text-align: center;
	width: calc(100% - 219px);
	background-color: #fafbfc;
	border: 1px solid #e1e4e8;
	border-radius: 6px;
	height: auto;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.section-name {
	font-size: 18px;
	font-weight: bold;
}

.divider {
	float: left;
	width: 100%;
	height: 10px;
	border-top: 2px solid #635bff;
}
