.sidebar {
	float: left;
	width: 180px;
	border-radius: 6px;
	background-color: #fafbfc;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.avatar {
	display: flex;
	justify-content: center;
	align-items: center;
}
.avatar > img {
	width: 180px;
	height: 240px;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	
}

.signature {
	padding: 5px;
	font-family: cursive;
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid #e1e4e8;
}

a {
	text-decoration: none;
}

.menuItem {
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
	text-decoration: none;
	color: #0a2540 !important;
	border-bottom: 1px solid #e1e4e8;
}

.menuItem:hover {
	background-color: #f3f4f6;
}

.active {
	padding-right: 0;
	border-right: 5px solid #635bff;
}

.linkIconContainer {
	display: flex;
	justify-content: center;
	text-decoration: none;
	align-items: center;
}

.linkIcon {
	float: left;
	padding: 5px;
}

.linkIcon > a > img {
	height: 20px;
	width: 20px;
}

.resume-download {
	margin-top: 20px;
	display: flex;
	justify-content: center;
	text-decoration: none;
	align-items: center;
	float: left;
	clear: left;
	height: 30px;
	width: 180px;
	padding-top: 5px;
	padding-bottom: 5px;
	border-radius: 6px;
	background-color: #fafbfc;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.resume-download a {
	text-decoration: none;
	color: #0a2540 !important;
}

.resume-download:hover {
	background-color: #f3f4f6;
}
