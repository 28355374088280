.intro {
	display: inline-block;
	text-align: center;
	width: 100%;
	height: 100%;
}

.header {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header .section-name {
	width: 40%;
	border-bottom: 2px solid #635bff;
	padding: 5px;
}

.intro p {
	text-align: left;
}

.intro .contact {
	padding-top: 40px;
}
